import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';

import strings from '../configs/strings';


import {
  useAuth,
  useUser
} from "@clerk/clerk-react";

const environment = process.env.REACT_APP_ENVIRONMENT; //prod or dev

let MAIN_API_NAME = strings.MAIN_API_NAME
// let PROMPT_PARAM_NAME = "prompt"

// for this app - could give template line images for users to use

let MAIN_API_URL = strings.MAIN_API_URL

function UserEmailAddress() {
  const { isLoaded, isSignedIn, user } = useUser();

  if (!isLoaded || !isSignedIn) {
    return "";{/*null;*/}
  }
  return user.primaryEmailAddress.emailAddress;
}

function FxInputForm3() {

    // make 2 components for each type,
    // then when making a form just insert in correct order

    const [imageURL1, setImageURL1] = useState(null);
    const [imageURL2, setImageURL2] = useState(null);

    const [audioURL1, setAudioURL1] = useState(null);
    const [audioURL2, setAudioURL2] = useState(null);

    const [videoURL1, setVideoURL1] = useState(null);
    const [videoURL2, setVideoURL2] = useState(null);

    const [selectedFile1, setSelectedFile1] = useState(null);
    const [selectedFile2, setSelectedFile2] = useState(null);
  
    const [textOutput, setTextOutput] = useState('');

    const [textInput1, setTextInput1] = useState('');
    const [textInput2, setTextInput2] = useState('');

    var formFileTypes = ["img"]; // represents all file types in form one by one // can be "img", "vid","aud","misc"

    // var imageURLStates = [];
    // var videoURLStates = [];
    // var textInputStates = [];
    // var textOutputStates = [];


    // eventually refactor so that you have 
    // arrays of each type, details of which defined by config file
    // then you generate the form from the config filee

    // ideally there would be -> handle[FTYPE]FileChange1,2,....

    
    const handleFileChange1 = (event) => {
      let outf = event.target.files[0];
      if (outf != null) {
        setSelectedFile1(outf);

        setImageURL1(null);
        setVideoURL1(null);
        setAudioURL1(null);
        if (formFileTypes.length > 0)
        {
          if (formFileTypes[0] == "img")
          {
            setImageURL1(URL.createObjectURL(outf));
          }
          else if (formFileTypes[0] == "aud")
          {
            setAudioURL1(URL.createObjectURL(outf));
          }
          else if (formFileTypes[0] == "vid")
          {
            setAudioURL1(URL.createObjectURL(outf));
          }
        }
      }
      else 
      {
        setSelectedFile1(null);
        setImageURL1(null);
        setVideoURL1(null);
        setAudioURL1(null);
      }
    };


    
    const handleFileChange2 = (event) => {
      let outf = event.target.files[0];
      if (outf != null) {
        setSelectedFile2(outf);

        setImageURL2(null);
        setVideoURL2(null);
        setAudioURL2(null);
        if (formFileTypes.length > 0)
        {
          if (formFileTypes[0] == "img")
          {
            setImageURL2(URL.createObjectURL(outf));
          }
          else if (formFileTypes[0] == "aud")
          {
            setAudioURL2(URL.createObjectURL(outf));
          }
          else if (formFileTypes[0] == "vid")
          {
            setAudioURL2(URL.createObjectURL(outf));
          }
        }
      }
      else 
      {
        setSelectedFile2(null);
        setImageURL2(null);
        setVideoURL2(null);
        setAudioURL2(null);
      }
    };

    
  
    //const authenticatedFetch = useFetch();
    const { getToken } = useAuth();
    const emailAddr = UserEmailAddress();

    
    const inputProps = {
      inputProps: {
        style: { textAlign: 'center' },
      },
    };
  
    const handleTextInputChange1 = event => {
        {/*console.log('Saving value');
    console.log(event.target.value);*/}
        setTextInput1(event.target.value);
    };

    const handleTextInputChange2 = event => {
        {/*console.log('Saving value');
    console.log(event.target.value);*/}
        setTextInput2(event.target.value);
    };

    const audioRef1 = useRef();
    const audioRef2 = useRef();
    const updateSong1 = () => { // reload the song, important or the current song won't change
      if(audioRef1.current){
        audioRef1.current.pause();
        audioRef1.current.load();
      }
    }
    const updateSong2 = () => { // reload the song, important or the current song won't change
      if(audioRef2.current){
        audioRef2.current.pause();
        audioRef2.current.load();
      }
    }

    let audioElement;
    let successMessage = ""
    if (textOutput == "")
    {
      audioElement = <p></p>;
    }
    else if (isValidHttpUrl(textOutput))
    {
      successMessage = <p>{"Success!!! Output below!"}</p>;
      audioElement = 
        <audio controls ref={audioRef2}>
          <source src={textOutput} type="audio/mpeg"></source>
        </audio>;
    }
    else {
      /* console.log("greet2 $textOutput"); */
      audioElement = <p>{textOutput}</p>;
    }
  
    
    
    function handleUpload() {
      // scroll to top
      scrollToTop()

      // Implement upload logic here, e.g., using Fetch API or a library like Axios
      setTextOutput("Loading...");

      const formData = new FormData();
      formData.append('image1', selectedFile1);
      formData.append('email', emailAddr);
      formData.append('env', environment);
      formData.append('api_name',MAIN_API_NAME)

      formData.append('faceatt',textInput1);
      formData.append('faceattdegree',textInput2);

      // updateSong after receiving response
      
      // console.log(selectedFile);

      const fetchX = async () => {
        const myToken = await getToken();
        // Make an API request to upload the file
        console.log(MAIN_API_URL);
        fetch(MAIN_API_URL, {
          mode: 'cors',
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${myToken}`,
          },
          body: formData,
        })
        .then(response => response.json())
        .then(response => {
          // scroll to top
          scrollToTop()

          // Handle response from server
          console.log(response["message"]);
          setTextOutput(response["message"]);
        })
        .catch(error => {
          // scroll to top
          scrollToTop()

          // Handle error
          setTextOutput("Sorry, action failed");//error.message);
          console.error(error);
          //console.log("error")
        });
      };
      fetchX();
    }

    const myaspectstyle = {
      width: "100%",
      height: "100%"
    }; {/*aim is to preserve aspect ratio of image*/}

    let imgElement;
    let outputDisplayText;

    let inputDisplayText = <h2>Input</h2>;

    if (textOutput == "")
    {
      imgElement = <p></p>;
      outputDisplayText = <p></p>;
    }
    else if (isValidHttpUrl(textOutput))
    {
      imgElement = <img src={textOutput} alt="a url 1"  style={myaspectstyle}></img>;
      outputDisplayText = <h2>Output</h2>;
    }
    else {
      /* console.log("greet2 $textOutput"); */
      imgElement = <p>{textOutput}</p>
      outputDisplayText = <h2>Output</h2>;
    }

    let imgElement1;
    if (imageURL1 && imageURL1.length > 2)
    {
      imgElement1 = <img src={imageURL1} alt="a url 1"  style={myaspectstyle}></img>
    }
    else {
      imgElement1 = <p></p>
    } 

    let outputArea = (
    <div>
    <Box display="flex" 
         alignItems="center" 
         bgcolor="white"
         marginTop={0} 
         marginBottom={0}
         justifyContent="center">
      {outputDisplayText}
    </Box>
    <Box display="flex" 
         alignItems="center" 
         bgcolor="white"
         marginTop={0} 
         marginBottom={5}
         marginLeft={0} 
         marginRight={0} 
         justifyContent="center">
      {imgElement}
    </Box>
    <hr />
    </ div>);
  
    if (textOutput == "")
    {
      outputArea = <div></div>
    }

    let instr1 = "edit_attribute: inversion, age, smile, eyes, lip, beard";
    let instr2 = "edit_degree: min -5, max 5, valid for age and smile";

    return (
      <div>
        {outputArea}
        <Box display="flex" 
             alignItems="center" 
             bgcolor="white"
             marginTop={0} 
             marginBottom={0}
             justifyContent="center">
          {inputDisplayText}
        </Box>
        <Box display="flex" 
             marginTop={0} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <p>Choose a source image</p>
        </Box>
        <Box display="flex" 
             marginTop={2} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <input type="file" accept="image/*" onChange={handleFileChange1} />
        <br />
        </Box>
        <Box display="flex" 
             marginTop={4} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        {instr1}
        </Box>
        <Box display="flex" 
             marginTop={2} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        {instr2}
        </Box>
        <Box display="flex" 
             marginTop={5} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <TextField 
          id="outlined-basic" 
          label="Attribute" 
          variant="outlined" 
          InputProps={inputProps}
          value={textInput1}
          multiline
          onChange={handleTextInputChange1}/>
        </Box>
        <Box display="flex" 
             marginTop={5} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <TextField 
          id="outlined-basic" 
          label="Degree (from -5 to 5)" 
          variant="outlined" 
          InputProps={inputProps}
          value={textInput2}
          multiline
          onChange={handleTextInputChange2}/>
        </Box>
        <Box display="flex" 
             marginTop={5} 
             marginBottom={5} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <Button variant="contained"
                onClick={handleUpload}>Submit Input</Button>
        </ Box>
        <Box display="flex" 
             marginTop={2} 
             marginBottom={8} 
             marginLeft={0} 
             marginRight={0} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center"
             objectFit="contain">
          {imgElement1}
        </Box>
      </div>
    );
  }

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // You can use 'auto' instead of 'smooth' for instant scrolling
    });
  }

  function isValidHttpUrl(string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }

  export default FxInputForm3;